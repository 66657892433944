import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './socials.module.css';

const Socials = () => {
  const { i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsMobile(true);
    }
  };

  const sendData = async (action: string, label: string = 'CUSTOM') => {
    let _data = JSON.stringify({
      ACTION: action,
      LABEL: label,
      DEVICE: isMobile ? 'MOBILE' : 'DESKTOP',
    });
    await fetch('https://id.egov.uz/api/v1/metrics/pushData', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data: _data,
      }),
    });
  };

  const openLink = async (link: string, label: string) => {
    await sendData('OPEN', label);
    window.open(link, '_blank');
  };
  const closeBanner = async () => {
    await sendData('REJECT');
    setIsVisible(false);
  };

  useEffect(() => {
    checkDevice();
  }, []);

  return (
    <>
      {isVisible ? (
        <div
          className={styles.banner} // Apply localized styles using CSS modules
          onMouseLeave={() => {
            !isSent && sendData('HOVER');
            setIsSent(true);
          }}
        >
          <button className={styles.cross + ' xs-show'} onClick={closeBanner}>
            <img src="/assets/img/socials/cross.svg" alt="" />
          </button>
          <button className={styles.cross + ' xs-hide'} onClick={closeBanner}>
            <img src="/assets/img/socials/cross-black.svg" alt="" />
          </button>
          <h3>
            <img
              className="xs-show"
              src="/assets/img/socials/my-gov-uz.svg"
              alt="mygov"
            />
            <img
              className="xs-hide"
              src="/assets/img/socials/my-gov-uz-black.svg"
              alt="mygov"
            />
            &nbsp; —
            {i18n.language === 'uz'
              ? ' орқали давлат хизматларидан уйдан чиқмаган ҳолда фойдаланинг!'
              : i18n.language === 'oz'
              ? ' orqali davlat xizmatlaridan uydan chiqmagan holda foydalaning!'
              : i18n.language === 'ru'
              ? ' возможность пользоваться государственными услугами, не выходя из дома!'
              : ' use public services without leaving your home!'}
          </h3>
          {i18n.language === 'uz' ? (
            <p> Қўшимча маълумот олиш учун - обуна бўлинг!</p>
          ) : i18n.language === 'oz' ? (
            <p> Qo'shimcha ma'lumot olish uchun - obuna bo'ling! </p>
          ) : i18n.language === 'ru' ? (
            <p> Для получения дополнительной информации - подписывайтесь! </p>
          ) : (
            <p> For more information - subscribe! </p>
          )}
          <div className={styles.socials}>
            <button
              onClick={() => openLink('https://t.me/MyGovUz', 'TELEGRAM')}
            >
              <img
                src="/assets/img/socials/tg.svg"
                className="tg"
                alt="telegram"
              />{' '}
              Telegram
            </button>
            <button
              onClick={() =>
                openLink('https://www.facebook.com/mygovuzb', 'FACEBOOK')
              }
            >
              <img src="/assets/img/socials/facebook.svg" alt="telegram" />{' '}
              Facebook
            </button>
            <button
              onClick={() =>
                openLink('https://instagram.com/my.gov.uz', 'INSTAGRAM')
              }
            >
              <img src="/assets/img/socials/instagram.svg" alt="instagram" />{' '}
              Instagram
            </button>
            <button
              onClick={() =>
                openLink('https://youtube.com/@mygovuzb', 'YOUTUBE')
              }
            >
              <img src="/assets/img/socials/youtube.svg" alt="youtube" />{' '}
              Youtube
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Socials;
